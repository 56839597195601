<template>
  <div class="generic-list scroll-container">
    <v-row>
      <v-col class="hidden-sm-and-down" cols="0" md="3">
        <MenuNavigator class="menu-navigator" />
      </v-col>
      <v-col cols="12" md="9" class="pa-3">
        <ProfileTitle :category="category" />
        <OrdersTable
          v-if="orders && orders.length > 0"
          :orders="orders"
          :ordersAllUsers="ordersAllUsers"
          @delete="deleteOrder"
          @addAllToCart="addAllToCart"
          @editOrder="editOrder"
        ></OrdersTable>
        <v-pagination
          v-if="orderPager && orderPager.totPages > 1"
          :value="pageFilter"
          :page="orderPager.selPage"
          :length="orderPager.totPages ? orderPager.totPages : 0"
          :totalVisible="7"
          @next.stop.prevent="handlePageFilter"
          @prev="handlePageFilter"
          @input="handlePageFilter"
        ></v-pagination>

        <v-card
          light
          outlined
          v-if="orders && orders.length == 0"
          class="pa-2 text-center"
          style="width:100%;"
        >
          <v-card-title class="headline">Nessun ordine trovato</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// @ is an alias to /src

import OrdersTable from "@/components/orders/OrdersTable";
import OrderService from "~/service/orderService";
import AnalyticsService from "~/service/analyticsService";
import MenuNavigator from "@/components/profile/MenuNavigator";

import ProfileTitle from "@/components/profile/ProfileTitle";

import categoryMixin from "~/mixins/category";
import reload from "~/mixins/reload";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Orders",
  data() {
    return { orders: {}, orderPager: {} };
  },
  mixins: [reload, categoryMixin],
  components: {
    OrdersTable,
    MenuNavigator,
    ProfileTitle
  },
  props: { ordersAllUsers: { type: Boolean, default: false } },
  computed: {
    ...mapGetters({
      pageFilter: "category/pageFilter"
    })
  },
  methods: {
    ...mapActions({
      addProductsFromOrder: "cart/addProductsFromOrder",
      loadCart: "cart/loadCart"
    }),
    reload() {
      if (this.ordersAllUsers) {
        OrderService.getOrdersForAllUserShippingAddresses(
          this.pageFilter,
          process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
          false,
          false,
          true
        ).then(data => {
          this.orders = data.orders;
          this.orderPager = data.page;
        });
      } else {
        OrderService.getOrders(
          this.pageFilter,
          process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE,
          true
        ).then(data => {
          this.orders = data.orders;
          this.orderPager = data.page;
        });
      }
    },
    async deleteOrder(orderId) {
      await OrderService.deleteOrder(orderId);
      this.reload();
      let order = await OrderService.getOrder(orderId);
      AnalyticsService.refund(order);
    },
    editOrder(orderId) {
      OrderService.editOrder(orderId).then(() => {
        this.loadCart();

        this.$router.push({
          name: "Checkout"
        });
        // this.fetchOrder(orderId);
        // this.orders = data.orders;
        // // this.page = data.page;
        // this.orderPager = data.page;
      });
    },
    async addAllToCart(orderId) {
      await this.addProductsFromOrder(orderId);
    },
    handlePageFilter(page) {
      this.$store.dispatch("category/setFilterPage", page);
      this.reload();
      this.$vuetify.goTo(0);
    }
  },
  created() {
    this.reload();
  }
};
</script>
